<template>
  <div>
    <Breadcrumbs main="" title="Incidents" />
    <div class="container-fluid">
      <div class="row">
        <incedent-vue></incedent-vue>
      </div>
    </div>
  </div>
</template>



<script>
import IncedentVue from "./Incedent.vue";
// import MonitorsController from "../../services/monitorController";
export default {
  components: { IncedentVue },
  data() {
    return {
      // all_monitors: [],
    };
  },
  mounted() {
    // this.getMonitors();
  },
  methods: {
    // async getMonitors() {
    //   let response = await MonitorsController.getMonitors();
    //   if (response.result) {
    //     response.data.map((t) => {
    //       this.all_monitors.push({
    //         value: t._id,
    //         text: t.name,
    //         type: t.type,
    //         isDown: t.isDown,
    //       });
    //     });
    //   }
    // },
  },
};
</script>